import React from 'react';
import { Link } from 'gatsby';

import Layout from '@common/Layout';
import { Container } from '@components/global';

const Privacy = () => (
  <Layout>
    <Container>
      <h1>Política de Privacidade</h1>

<br />
Última atualização: 31 de Agosto de 2023
<br />
<br />

A sua privacidade é de extrema importância para nós. Esta política de privacidade descreve como coletamos, usamos e protegemos suas informações pessoais quando você visita ou usa nosso site.
<br />

<h2>Coleta de Informações </h2>
   • Coletamos informações que você fornece diretamente a nós, como nome, endereço de e-mail, número de telefone e qualquer outra informação que você opte por fornecer.<br />
   • Quando você visita nosso site, coletamos automaticamente informações sobre o seu dispositivo, incluindo seu endereço IP, tipo de navegador e páginas visitadas.<br />

<br />
<h2> Uso de Informações </h2>
   • Usamos as informações coletadas para responder a suas solicitações, melhorar nosso site, enviar newsletters e para fins de marketing.<br />

<br />
<h2> Compartilhamento de Informações </h2>
   • Não vendemos, alugamos ou compartilhamos suas informações pessoais com terceiros, exceto conforme descrito nesta política ou com o seu consentimento.<br />

<br />
<h2> Segurança </h2>
   • Tomamos medidas de segurança para proteger suas informações pessoais contra acesso, uso ou divulgação não autorizados. No entanto, nenhum método de transmissão ou armazenamento é 100% seguro, por isso não podemos garantir a segurança total de suas informações.<br />

<br />
<h2> Cookies e Tecnologias Similares </h2>
   • Utilizamos cookies e outras tecnologias para coletar informações sobre você e sua atividade em nosso site. Você pode optar por desativar os cookies, mas isso pode afetar sua capacidade de usar certas partes do nosso site.<br />

<br />
<h2> Links para Outros Sites </h2>
   • Nosso site pode conter links para outros sites. Não somos responsáveis pelas práticas de privacidade ou pelo conteúdo desses sites.<br />

<br />
<h2> Alterações na Política de Privacidade </h2>
   • Podemos atualizar nossa política de privacidade periodicamente. Qualquer alteração será publicada nesta página com a data da última atualização no topo.<br />

<br />
<h2> Contato </h2>
   • Se você tiver alguma dúvida sobre esta política de privacidade, entre em contato conosco em contato@coruja.io.

<br />
<br />
<br />
Ao usar nosso site, você concorda com os termos desta política de privacidade. Se você não concordar com esta política, por favor, não use nosso site.

  <br />
  <br />
	  <p>
		<Link to="/"> Voltar ao início </Link>
	  </p>

    </Container>
  </Layout>
);

export default Privacy;
